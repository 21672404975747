




















import Vue from 'vue'
import { Prop, Component, Watch, Mixins } from 'vue-property-decorator'
// components
import BeatTable from '@/components/BeatTable.vue'
import TileSlider from '@/components/TileSlider.vue'
import TileSliderPlaceholder from '@/components/TileSliderPlaceholder.vue'
// api
import { fetchPlaylistsWithFilter, PlaylistFilterType } from '@/api/playlists'
import { fetchSingleGenre } from '@/api/genre'
import { fetchBeats, BeatFilterType } from '~/api/beats'
import { InfiniteLoadingSetState } from '~/mixins/infinite-loading'
import { ParentStateType } from '~/const/parent-state-type'

@Component({ components: { BeatTable, TileSlider, TileSliderPlaceholder } })
export default class GenrePage extends Mixins(InfiniteLoadingSetState) {
    @Prop({ type: String }) slug!: string

    ParentStateType = ParentStateType

    genre: IGenre = null
    genrePlaylists: IPlaylist[] = []
    beats: IBeat[] = []

    get backgroundImageLink() {
        return this.genre ? `url(${this.genre.image_background_url})` : 'url()'
    }

    async fetchGenreAndGenrePlaylists() {
        this.genre = await fetchSingleGenre(this.slug)
        this.genrePlaylists = await fetchPlaylistsWithFilter({ filterType: PlaylistFilterType.Genre, filterId: this.genre.id })
    }

    async updateBeatsTable($state: any, offset: number) {
        // call the store to update the beats
        const beats = await fetchBeats({ filterType: BeatFilterType.Genres, slug: this.slug, offset })
        this.beats.length > 0 ? this.beats.push(...beats) : (this.beats = beats)
        // set loading state for vue-infinite-loading
        this.setLoadingStateAndUpdatePlayerQueue($state, beats)
    }

    fetchEverything() {
        this.fetchGenreAndGenrePlaylists()
        this.updateBeatsTable(null, 0)
    }

    @Watch('slug')
    onSlugChange(newValue: string) {
        // reset beats list
        this.beats = []
        this.fetchEverything()
    }

    mounted() {
        this.fetchEverything()
    }
}
