var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "view" }, [
    _c(
      "header",
      {
        staticClass: "header header__gradient header__gradient--fromBottom",
        style: { "background-image": _vm.backgroundImageLink },
      },
      [
        _c("div", { staticClass: "container container--sm" }, [
          _c("h1", { staticClass: "h1 h1--lg" }, [
            _vm._v(_vm._s(_vm.genre ? _vm.genre.name : "Loading...")),
          ]),
        ]),
      ]
    ),
    _vm.genrePlaylists && _vm.genrePlaylists.length > 0
      ? _c(
          "section",
          { staticClass: "section", staticStyle: { "margin-bottom": "60px" } },
          [
            _c("div", { staticClass: "container" }, [
              _c("h3", { staticClass: "h3 h3__sectionTitle" }, [
                _vm._v("Playlists"),
              ]),
              _c(
                "div",
                { staticClass: "wrapper" },
                [
                  _c("TileSlider", {
                    attrs: {
                      "parent-state": "playlists",
                      "loaded-items": _vm.genrePlaylists,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        )
      : _vm._e(),
    _c("section", { staticClass: "section" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _vm.genrePlaylists && _vm.genrePlaylists.length > 0
            ? _c("h3", { staticClass: "h3 h3__sectionTitle" }, [
                _vm._v(
                  _vm._s(_vm.genre ? _vm.genre.name : "Loading...") + " Beats"
                ),
              ])
            : _vm._e(),
          _c("BeatTable", {
            attrs: {
              parentState: _vm.ParentStateType.Genre,
              beatPlaylist: _vm.beats,
            },
            on: {
              fetchMoreData: function ($event) {
                return _vm.updateBeatsTable($event, _vm.beats.length)
              },
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }